import {
  nextTick, ref, watch, computed,
} from '@vue/composition-api'

import store from '@/store'
import { flightTypeOptions } from '@/constants/saleReport'
import { saleReportTicketTypeOptions } from '@/constants/selectOptions'
import { useRouter } from '@/@core/utils/utils'

import { convertISODateTime } from '@core/utils/filter'

import useToast from '@useToast'

export default function useInvoiceCreateHandle() {
  const { router } = useRouter()

  const SALE_REPORT_STORE = 'app-sale-report'

  const { toastSuccess, toastError } = useToast()
  const loading = ref(false)

  const createInvoice = payload => {
    loading.value = true
    store
      .dispatch('app-invoice/createInvoice', payload)
      .then(res => {
        toastSuccess('Lập hoá đơn thành công')
        if (res?.id) {
          // router.push({ name: 'apps-invoiceDetail', params: { id: res?.id } })
          router.push({ name: 'apps-invoiceList' })
        }
      })
      .catch(error => {
        console.error({ error })
        toastError('Lỗi tải danh sách hoá đơn')
      })
      .finally(() => {
        loading.value = false
      })
  }

  const loadingGetCompanyByTaxCode = ref(false)
  async function getCompanyByTaxCode(taxCode) {
    if (!taxCode) return
    loadingGetCompanyByTaxCode.value = true
    try {
      const res = await store.dispatch('app-invoice/getCompanyByTaxCode', taxCode)
      if (res.status === 200) {
        // eslint-disable-next-line consistent-return
        return res.data
      }
    } catch (e) {
      toastError('Không tìm thấy thông tin doanh nghiệp')
    } finally {
      loadingGetCompanyByTaxCode.value = false
    }
  }

  return {
    createInvoice,
    loading,
    loadingGetCompanyByTaxCode,
    getCompanyByTaxCode,

    // constants
    SALE_REPORT_STORE,
  }
}

export function useSelectTicketHandle() {
  const { toastError } = useToast()

  const refSaleReportListTableSelectTicket = ref()
  const loadingSelectTicket = ref(false)
  const startDateFilter = computed({
    get: () => store.getters['app-invoice/getFiltersSaleReport'].startDate,
    set: val => store.dispatch('app-invoice/setFiltersSaleReport', { startDate: val }),
  }) // từ ngày
  const endDateFilter = computed({
    get: () => store.getters['app-invoice/getFiltersSaleReport'].endDate,
    set: val => store.dispatch('app-invoice/setFiltersSaleReport', { endDate: val }),
  }) // đến ngày
  const ticketNumberFilter = computed({
    get: () => store.getters['app-invoice/getFiltersSaleReport'].ticketNumber,
    set: val => store.dispatch('app-invoice/setFiltersSaleReport', { ticketNumber: val }),
  }) // số vé
  const ticketTypeFilter = computed({
    get: () => store.getters['app-invoice/getFiltersSaleReport'].ticketType,
    set: val => store.dispatch('app-invoice/setFiltersSaleReport', { ticketType: val }),
  }) // loại vé
  const airlineFilter = computed({
    get: () => store.getters['app-invoice/getFiltersSaleReport'].airline,
    set: val => store.dispatch('app-invoice/setFiltersSaleReport', { airline: val }),
  }) // Hãng HK
  const paxNameFilter = computed({
    get: () => store.getters['app-invoice/getFiltersSaleReport'].paxName,
    set: val => store.dispatch('app-invoice/setFiltersSaleReport', { paxName: val }),
  }) // Tên hành khách
  const bookingCodeFilter = computed({
    get: () => store.getters['app-invoice/getFiltersSaleReport'].bookingCode,
    set: val => store.dispatch('app-invoice/setFiltersSaleReport', { bookingCode: val }),
  }) // Mã đặt chỗ
  const agencyCodeFilter = computed({
    get: () => store.getters['app-invoice/getFiltersSaleReport'].agencyCode,
    set: val => store.dispatch('app-invoice/setFiltersSaleReport', { agencyCode: val }),
  }) // Mã đại lý
  const flightTypeFilter = computed({
    get: () => store.getters['app-invoice/getFiltersSaleReport'].flightType,
    set: val => store.dispatch('app-invoice/setFiltersSaleReport', { flightType: val }),
  }) // nội đia/ quốc tế

  const sizePerPage = ref(40)
  const currentPage = ref(1)
  const totalItems = ref(0)
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC
  const searchText = ref(null)

  const refetchData = () => {
    refSaleReportListTableSelectTicket.value.refresh()
  }

  const clearFilter = () => {
    startDateFilter.value = convertISODateTime(new Date()).dateFilter
    endDateFilter.value = convertISODateTime(new Date()).dateFilter
    ticketNumberFilter.value = ''
    ticketTypeFilter.value = ''
    airlineFilter.value = ''
    paxNameFilter.value = ''
    bookingCodeFilter.value = ''
    agencyCodeFilter.value = ''
    flightTypeFilter.value = 'Domestic'

    nextTick(() => {
      refetchData()
    })
  }

  watch(flightTypeFilter, () => {
    refetchData()
  })

  const includeInvoiceId = ref()

  const fetchSaleReports = (ctx, callback) => {
    loadingSelectTicket.value = true

    store.dispatch('app-sale-report/fetchSaleReports', {
      startDate: startDateFilter.value || undefined,
      endDate: endDateFilter.value || undefined,
      searchText: searchText.value || undefined,
      ticketNumber: ticketNumberFilter.value || undefined,
      saleReportTicketType: ticketTypeFilter.value || undefined,
      airline: airlineFilter.value || undefined,
      paxName: paxNameFilter.value || undefined,
      bookingCode: bookingCodeFilter.value || undefined,
      agencyCode: agencyCodeFilter.value || undefined,
      flightType: flightTypeFilter.value || undefined,
      sortBy: sortBy.value ? sortBy.value : 'issueDate',
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
      includeInvoiceId: includeInvoiceId.value ? Number(includeInvoiceId.value) : undefined,
      isInvoiced: false,
      size: sizePerPage.value,
      page: currentPage.value,
    })
      .then(response => {
        totalItems.value = response.data.total
        callback(response.data.items || [])
      })
      .catch(error => {
        toastError({
          title: 'messagesList.error',
          content: `${error.message || error}`,
        })
      })
      .finally(() => {
        loadingSelectTicket.value = false
      })
  }

  return {
    // filters
    startDateFilter,
    endDateFilter,
    ticketNumberFilter,
    ticketTypeFilter,
    airlineFilter,
    paxNameFilter,
    bookingCodeFilter,
    agencyCodeFilter,
    flightTypeFilter,
    fetchSaleReports,

    loadingSelectTicket,
    refetchData,
    refSaleReportListTableSelectTicket,
    flightTypeOptions,
    saleReportTicketTypeOptions,
    clearFilter,
    includeInvoiceId,
  }
}
