<template lang="">
  <!-- SECTION Chọn vé -->
  <BCard
    body-class="p-50"
    class="mb-0"
  >
    <div
      v-if="!isModal"
      class="d-flex-between gap-1 bg-primary-gradient"
    >
      <div class="d-flex-center justify-content-start gap-1">
        <IAmIcon
          icon="ticket"
          size="25"
          class="p-1 icon-card"
          @dblclick.native.prevent="onQuickChooseDateHandle"
        />
        <span class="font-medium-3 m-0 fw-700">
          {{ $t('invoice.create.selectTicket.title') }}
        </span>
      </div>
      <BButton
        variant="flat-dark"
        class="p-50"
        @click="$emit('update:collapseSelectedTicket', !collapseSelectedTicket)"
      >
        <IAmIcon
          icon="resize"
          size="18"
        />
      </BButton>
    </div>

    <!-- SECTION Filter -->
    <!-- NOTE Row 1 -->
    <BRow class="px-75">
      <!-- ANCHOR Từ ngày -->
      <b-col
        cols="12"
        class="px-25"
        md="3"
      >
        <b-form-group
          class="mb-0"
          label-class="h5 py-0"
          :label="$t('invoice.filters.from')"
        >
          <flat-pickr
            v-model="startDateFilter"
            class="form-control"
            :placeholder="`DD/MM/YYYY`"
            :disabled="!isEditing"
            :config="{
              allowInput: true,
              dateFormat: 'Y-m-d',
              altFormat: 'd/m/Y',
              altInput: true,
              maxDate: endDateFilter ? endDateFilter : today,
              locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
              disableMobile: true,
            }"
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR Đến ngày -->
      <b-col
        cols="12"
        class="px-25"
        md="3"
      >
        <b-form-group
          class="mb-0"
          label-class="h5 py-0"
          :label="$t('invoice.filters.to')"
        >
          <flat-pickr
            v-model="endDateFilter"
            class="form-control"
            :disabled="!isEditing"
            :placeholder="`DD/MM/YYYY`"
            :config="{
              allowInput: true,
              dateFormat: 'Y-m-d',
              altFormat: 'd/m/Y',
              altInput: true,
              minDate: startDateFilter,
              maxDate: today,
              locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
              disableMobile: true,
            }"
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR Số vé -->
      <b-col
        cols="12"
        class="px-25"
        md="3"
      >
        <b-form-group
          class="mb-0"
          label-class="h5 py-0 fw-600"
          :label="$t('invoice.create.selectTicket.ticketNumber')"
        >
          <b-form-input
            v-model.trim="ticketNumberFilter"
            :placeholder="$t('invoice.create.selectTicket.phTicketNumber')"
            :disabled="!isEditing"
            :formatter="onlyUpperCaseFormatter"
            @keyup.enter="refetchData"
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR Loại vé -->
      <b-col
        cols="12"
        class="px-25"
        md="3"
      >
        <b-form-group
          class="mb-0"
          label-class="h5 py-0 fw-600"
          :label="$t('invoice.create.selectTicket.ticketType')"
        >
          <v-select
            v-model="ticketTypeFilter"
            class="w-100 vue-select-class"
            style="font-size: 1rem;"
            :options="saleReportTicketTypeOptions"
            :disabled="!isEditing"
            label="label"
            :clearable="true"
            :placeholder="$t('invoice.create.selectTicket.phTicketType')"
            :reduce="val => val.value"
          >
            <template #option="data">
              {{ $t(data.label) }}
            </template>

            <template #selected-option="data">
              {{ $t(data.label) }}
            </template>
            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </BRow>

    <!-- NOTE Row 2 -->
    <BRow class="px-75">
      <!-- ANCHOR Hãng HK -->
      <b-col
        cols="12"
        class="px-25"
        md="3"
      >
        <b-form-group
          class="mb-0"
          label-class="h5 py-0 fw-600"
          :label="$t('invoice.create.selectTicket.airline')"
        >
          <b-form-input
            v-model.trim="airlineFilter"
            :placeholder="$t('saleReport.phAirline')"
            :formatter="trimUpperCaseInput"
            :disabled="!isEditing"
            maxlength="2"
            @keyup.enter="refetchData"
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR Tên hành khách -->
      <b-col
        cols="12"
        class="px-25"
        md="3"
      >
        <b-form-group
          class="mb-0"
          label-class="h5 py-0 fw-600"
          :label="$t('invoice.create.selectTicket.passengerName')"
        >
          <b-form-input
            v-model.trim="paxNameFilter"
            :placeholder="$t('invoice.create.selectTicket.passengerName')"
            :disabled="!isEditing"
            :formatter="onlyUpperCaseFormatter"
            @keyup.enter="refetchData"
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR Mã ĐC/ĐH -->
      <b-col
        cols="12"
        class="px-25"
        md="3"
      >
        <b-form-group
          class="mb-0"
          label-class="h5 py-0 fw-600"
          :label="$t('invoice.create.selectTicket.bookingCode')"
        >
          <b-form-input
            v-model.trim="bookingCodeFilter"
            :placeholder="$t('invoice.create.selectTicket.phBookingCode')"
            :disabled="!isEditing"
            :formatter="onlyUpperCaseFormatter"
            @keyup.enter="refetchData"
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR Mã đại lý -->
      <b-col
        cols="12"
        class="px-25"
        md="3"
      >
        <b-form-group
          class="mb-0"
          label-class="h5 py-0 fw-600"
          :label="$t('invoice.create.selectTicket.agencyCode')"
        >
          <vue-autosuggest
            id="agency-code"
            v-model="agencyCodeFilter"
            :suggestions="agencySuggestions"
            :input-props="{
              placeholder: `${$t('saleReport.phAgencyCode')}`,
              class: `form-control ${agencyCodeFilter ? 'text-uppercase' : ''}`,
              disabled: !isEditing
            }"
            :get-suggestion-value="getSuggestionValue"
            @input="inputSuggestionHandle"
            @keyup.enter="refetchData"
          >
            <template slot-scope="{ suggestion }">
              <span class="d-block font-weight-bold text-truncate text-uppercase">
                <span class="text-info">{{ suggestion.item.agencyCode }}</span> <small>({{ suggestion.item.agencyName }})</small>
              </span>
            </template>
          </vue-autosuggest>
        </b-form-group>
      </b-col>
    </BRow>
    <!-- !SECTION -->

    <!-- ANCHOR TOP TABLE -->
    <div class="my-25 d-flex-between gap-1">
      <BButton
        variant="info"
        size="sm"
        :disabled="!isEditing"
        class="d-flex-center gap-1"
        @click="refetchData"
      >
        <IAmIcon
          icon="search"
          size="15"
          color="white"
        />
        {{ $t('invoice.create.selectTicket.search') }}
      </BButton>
      <BButton
        variant="danger"
        size="sm"
        :disabled="!isEditing"
        class="d-flex-center gap-1"
        @click="clearFilter"
      >
        <IAmIcon
          icon="trash"
          size="15"
          color="white"
        />
        {{ $t('invoice.create.selectTicket.clearFilter') }}
      </BButton>
    </div>

    <div class="d-flex-between my-25">
      <b-form-radio-group
        v-model="flightTypeFilter"
        class="radio-custom"
        :disabled="!isEditing"
        name="radio-sub-component"
      >
        <b-form-radio
          v-for="item in flightTypeOptions"
          :key="item.value"
          :value="item.value"
        >
          {{ $t(`invoice.create.selectTicket.${item.value.toLowerCase()}Ticket`) }}
        </b-form-radio>
      </b-form-radio-group>
      <BButton
        v-if="!isModal"
        variant="flat-info"
        :disabled="!isEditing"
        size="sm"
        @click="viewDetailsHandle"
      >
        <div class="d-flex-center gap-1">
          <span style="text-decoration: underline">{{ $t('invoice.create.selectTicket.viewDetails') }}</span>
          <IAmIcon
            icon="hyperlink"
            size="16"
            color="#2FA4E5"
          />
        </div>
      </BButton>
    </div>

    <!-- ANCHOR Table -->
    <b-table
      ref="refSaleReportListTableSelectTicket"
      style="max-height: 70vh"
      :sticky-header="true"
      class="position-relative mb-0"
      :items="fetchSaleReports"
      select-mode="multi"
      responsive
      primary-key="id"
      show-empty
      :empty-text="$t('noMatchingResult')"
      no-border-collapse
      small
      borderless
      hover
      :fields="tableColumnSelectTicket"
      :busy="loadingSelectTicket"
    >
      <template
        v-for="column in tableColumnSelectTicket"
        #[`head(${column.key})`]="data"
      >
        <span
          :key="column.key"
          class="text-dark text-nowrap"
        >
          {{ $t(`invoice.create.selectTicket.columns.${data.column}`) }}
        </span>
      </template>

      <template #table-busy>
        <!-- FIXME {{ loading }} -->
        <div class="d-flex-center text-dark my-2 gap-2">
          <b-spinner class="align-middle" />
          <strong>{{ $t('loading') }}</strong>
        </div>
      </template>
      <!-- ANCHOR checkbox -->
      <template #cell(checkbox)="{ item }">
        <b-form-checkbox
          :checked="isTicketSelectedCheck(item)"
          :disabled="!isEditing"
          @change="onSelectRow(item)"
        />
      </template>
      <!-- ANCHOR bookingCode -->
      <template #cell(bookingCode)="{ item }">
        <div class="font-weight-bolder">
          {{ item.bookingCode }}
        </div>
      </template>
      <!-- ANCHOR paxName -->
      <template #cell(paxName)="{ item }">
        <div class="text-nowrap">
          {{ item.paxName }}
        </div>
      </template>
      <!-- ANCHOR ticketNumber -->
      <template #cell(ticketNumber)="{ item }">
        <div class="text-nowrap">
          {{ item.ticketNumber }}
        </div>
      </template>
      <!-- ANCHOR trips -->
      <template #cell(trips)="{ item: { trips } }">
        <div v-if="trips">
          <div
            v-for="(trip, tripIndex) in convertShortenTrips(trips)"
            :key="tripIndex"
          >
            {{ trip }}
          </div>
        </div>
      </template>
      <!-- ANCHOR agencyCode -->
      <template #cell(agencyCode)="{ item }">
        <div class="text-nowrap text-uppercase">
          {{ item?.agency?.agencyCode || '' }}
        </div>
      </template>
      <!-- ANCHOR receivables -->
      <template #cell(receivables)="{ item }">
        <div class="text-nowrap text-right fw-600">
          {{ formatCurrency(item?.receivables || 0) }}
        </div>
      </template>

      <!-- ANCHOR flightDates -->
      <template #cell(flightDates)="{ item: { flightDates } }">
        {{ replaceText(flightDates) }}
      </template>
      <!-- ANCHOR fareBasisCode -->
      <template #cell(fareBasisCode)="{ item: { fareBasisCode } }">
        {{ replaceText(fareBasisCode) }}
      </template>

      <!-- ANCHOR bookingDate -->
      <template #cell(bookingDate)="{ item }">
        <div>
          {{ replaceText(convertISODateTime(item.bookingDate).dateTime) }}
        </div>
      </template>

      <!-- ANCHOR ticketType -->
      <template #cell(ticketType)="{ item }">
        <div>
          {{ $te(`saleReport.${item.ticketType}`) ? $t(`saleReport.${item.ticketType}`) : item.ticketType }}
        </div>
      </template>

      <!-- ANCHOR balanceAdjustment -->
      <template #cell(balanceAdjustment)="{ item: { balanceAdjustment} }">
        <div class="text-right text-nowrap">
          {{ formatCurrency(balanceAdjustment) }}
        </div>
      </template>

      <!-- ANCHOR priceTicket -->
      <template #cell(priceTicket)="{ item: { priceTicket } }">
        <div class="text-right text-nowrap">
          {{ formatCurrency(priceTicket) }}
        </div>
      </template>

      <!-- ANCHOR collection fee -->
      <template #cell(collectionFee)="{ item: { collectionFee } }">
        <div class="text-right text-nowrap">
          {{ formatCurrency(collectionFee) }}
        </div>
      </template>

      <!-- ANCHOR tax -->
      <template #cell(tax)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.tax) }}
        </div>
      </template>

      <!-- ANCHOR otherTax -->
      <template #cell(otherTax)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.otherTax) }}
        </div>
      </template>

      <!-- ANCHOR fee -->
      <template #cell(fee)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.fee) }}
        </div>
      </template>

      <!-- ANCHOR feeIn -->
      <template #cell(feeIn)="{ item }">
        <div class="text-right">
          {{ formatCurrency(isRoleF2 ? item.feeOut : item.feeIn) }}
        </div>
      </template>

      <!-- ANCHOR feeOut -->
      <template #cell(feeOut)="{ item }">
        <div class="text-right">
          {{ formatCurrency(isRoleF2 ? item.feeIn : item.feeOut ) }}
        </div>
      </template>

      <!-- ANCHOR feeService -->
      <template #cell(feeService)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.feeService) }}
        </div>
      </template>

      <!-- ANCHOR commissionPrepaid -->
      <template #cell(commissionPrepaid)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.commissionPrepaid) }}
        </div>
      </template>
      <!-- ANCHOR commissionPrepaidIn -->
      <template #cell(commissionPrepaidIn)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.commissionPrepaidIn) }}
        </div>
      </template>
      <!-- ANCHOR commissionPrepaidOut -->
      <template #cell(commissionPrepaidOut)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.commissionPrepaidOut) }}
        </div>
      </template>

      <!-- ANCHOR unpaidCommission -->
      <template #cell(unpaidCommission)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.unpaidCommission) }}
        </div>
      </template>

      <!-- ANCHOR unpaidCommissionIn -->
      <template #cell(unpaidCommissionIn)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.unpaidCommissionIn) }}
        </div>
      </template>

      <!-- ANCHOR unpaidCommissionOut -->
      <template #cell(unpaidCommissionOut)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.unpaidCommissionOut) }}
        </div>
      </template>

      <!-- ANCHOR commission -->
      <template #cell(commission)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.commission) }}
        </div>
      </template>

      <!-- ANCHOR tradeCreditors -->
      <template #cell(tradeCreditors)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.tradeCreditors) }}
        </div>
      </template>

      <!-- ANCHOR profit: Lợi nhuận -->
      <template #cell(profit)="{ item }">
        <div class="text-right">
          {{ formatCurrency(item.profit) }}
        </div>
      </template>

      <!-- ANCHOR totalPayment -->
      <template #cell(totalPayment)="{ item: { totalPayment } }">
        <div class="font-weight-bold text-right">
          {{ formatCurrency(totalPayment) }}
        </div>
      </template>

      <!-- ANCHOR createdAt -->
      <template #cell(createdAt)="data">
        <div class="mb-0">
          {{ replaceText(dateTime(data.item.createdAt)) }}
        </div>
      </template>

      <!-- ANCHOR updatedAt -->
      <template #cell(updatedAt)="data">
        <div class="mb-0">
          {{ replaceText(dateTime(data.item.updatedAt)) }}
        </div>
      </template>
      <!-- ANCHOR agency -->
      <template #cell(agency)="data">
        <div class="mb-0 text-nowrap">
          {{ data.item.agency.agencyCode.toUpperCase() }}
        </div>
      </template>

      <!-- ANCHOR booker -->
      <template #cell(booker)="data">
        <div
          v-if="data.item.booker"
          class="mb-0 text-nowrap"
        >
          {{ data.item.booker.username.toUpperCase() }}
        </div>
      </template>

      <!-- ANCHOR issuer -->
      <template #cell(issuer)="data">
        <div
          v-if="data.item.issuer"
          class="mb-0 text-nowrap"
        >
          {{ data.item.issuer.username.toUpperCase() }}
        </div>
      </template>

      <!-- ANCHOR issueDate -->
      <template #cell(issueDate)="{ item: { issueDate } }">
        {{ replaceText(convertISODateTime(issueDate).dateTime) }}
      </template>
    </b-table>

    <ModalSelectTicket :is-modal="true" />
  </BCard>
  <!-- !SECTION -->
</template>
<script>
import {
  BCard, BCol, BRow, BFormGroup, BFormInput, BButton, BFormRadio, BFormRadioGroup, BTable,
  BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import debounce from 'lodash/debounce'
import { VueAutosuggest } from 'vue-autosuggest'
import moment from 'moment'

import {
  formatCurrency, convertISODateTime, replaceText, dateTime,
} from '@/@core/utils/filter'
import store from '@/store'
import { apiAgencies } from '@/api'
import { useSelectTicketHandle } from '@/views/invoices/create/useInvoiceCreateHandle'
import { useRouter } from '@/@core/utils/utils'

import { onlyUpperCaseFormatter, trimUpperCaseInput } from '@core/comp-functions/forms/formatter-input'

import { convertShortenTrips } from '@saleReport/useSaleReportHandle'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BFormRadio,
    BFormRadioGroup,
    BTable,
    BFormCheckbox,
    flatPickr,
    BSpinner,
    vSelect: () => import('vue-select'),
    ModalSelectTicket: () => import('@/views/invoices/components/ModalSelectTicket.vue'),
    VueAutosuggest,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    invoiceData: {
      type: Object,
      default: () => {},
    },
    collapseSelectedTicket: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
    isReplace: {
      type: Boolean,
      default: false,
    },
    isAdjust: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      fetchSaleReports,
      loadingSelectTicket,
      refSaleReportListTableSelectTicket,
      refetchData,
      startDateFilter,
      endDateFilter,
      flightTypeFilter,
      ticketNumberFilter,
      ticketTypeFilter,
      airlineFilter,
      paxNameFilter,
      bookingCodeFilter,
      agencyCodeFilter,
      flightTypeOptions,
      saleReportTicketTypeOptions,
      clearFilter,
      includeInvoiceId,
    } = useSelectTicketHandle()

    const { route } = useRouter()
    includeInvoiceId.value = route.value.params?.id

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    const isHideFee = computed(() => store.getters['userStore/getHideFee'])

    const SALE_REPORT_TABLE_COLUMNS = [
      { key: 'checkbox', stickyColumn: true },
      { key: 'source' },
      { key: 'bookingCode' },
      { key: 'paxName' },
      { key: 'paxType' },
      { key: 'ticketNumber' },
      { key: 'trips' },
      { key: 'airline' },
      { key: 'flightDates' },
      { key: 'bookingClass' },
      { key: 'paymentCode' },
      { key: 'ticketType' },
      { key: 'flightType' },
      { key: 'fareBasisCode' },
      { key: 'bookingDate' },
      { key: 'issueDate' },
      { key: 'booker' },
      { key: 'issuer' },
      { key: 'agencyCode' },
      { key: 'signBook' },
      { key: 'currency' },
      { key: 'priceTicket' },
      { key: 'tax' },
      { key: 'otherTax' },
      { key: 'collectionFee' },
      { key: 'feeIn' },
      { key: 'feeOut' },
      { key: 'feeService' },
      { key: 'commissionPrepaidIn' },
      { key: 'commissionPrepaidOut' },
      { key: 'unpaidCommissionIn' },
      { key: 'unpaidCommissionOut' },
      { key: 'commission' },
      { key: 'tradeCreditors' },
      { key: 'receivables', stickyColumn: true, class: 'position-right-0' },
      { key: 'balanceAdjustment' },
      { key: 'profit' },
      { key: 'createdAt' },
      { key: 'updatedAt' },
    ]

    const tableColumnSelectTicket = computed(() => {
      let columns = props.isModal
        ? SALE_REPORT_TABLE_COLUMNS
        : SALE_REPORT_TABLE_COLUMNS.filter(col => [
          'checkbox',
          'bookingCode',
          'paxName',
          'ticketNumber',
          'trips',
          'airline',
          'agencyCode',
          'ticketType',
          'receivables',
        ].includes(col.key))

      if (isRoleF1.value) {
        columns = columns.filter(item => !['tradeCreditors'].includes(item.key))
      } else {
        columns = columns.filter(item => !['collectionFee', 'tax'].includes(item.key))
      }
      if (isRoleF3.value) {
        const removeField = ['feeIn']
        if (isHideFee.value) {
          removeField.push('feeOut')
        }
        columns = columns.filter(item => !removeField.includes(item.key))
      }

      return columns
    })

    const airline = ref()
    const agencyCode = ref()
    const ticketType = ref()
    const bookingCode = ref()
    const passengerName = ref()
    const ticketNumber = ref()
    const selected = ref()
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    function viewDetailsHandle() {
      this.$bvModal.show('modal-invoice-create-select-ticket')
    }

    function onSelectRow(item) {
      store.dispatch('app-invoice/setSelectedTicket', {
        item,
        valueAddedTax: props.invoiceData?.valueAddedTax !== 'OTHER' ? props.invoiceData?.valueAddedTax : undefined,
      })
    }

    function isTicketSelectedCheck(item) {
      const res = store.getters['app-invoice/findSelectedTicket'](item)
      return !!res
    }

    // for agency code filter
    function getSuggestionValue(suggestion) {
      agencyCodeFilter.value = suggestion.item.agencyCode
      return suggestion.item.agencyCode
    }

    const agencySuggestions = ref([
      {
        data: [],
      },
    ])
    const inputSuggestionHandle = debounce(input => {
      if (input === '' || input === undefined) {
        return
      }
      apiAgencies
        .fetchAgencies({ searchText: input })
        .then(response => {
          agencySuggestions.value = [
            {
              data: response.data.items,
            },
          ]
        })
    }, 300)

    function onQuickChooseDateHandle() {
      startDateFilter.value = moment(endDateFilter.value).subtract(30, 'd').format('YYYY-MM-DD')
      refetchData()
    }
    return {
      onlyUpperCaseFormatter,
      Vietnamese,
      tableColumnSelectTicket,
      formatCurrency,
      convertISODateTime,
      airline,
      agencyCode,
      ticketType,
      bookingCode,
      passengerName,
      ticketNumber,
      viewDetailsHandle,
      fetchSaleReports,
      selected,
      today,
      startDateFilter,
      flightTypeFilter,
      endDateFilter,
      ticketNumberFilter,
      ticketTypeFilter,
      airlineFilter,
      paxNameFilter,
      bookingCodeFilter,
      agencyCodeFilter,
      loadingSelectTicket,
      convertShortenTrips,
      refSaleReportListTableSelectTicket,
      onSelectRow,
      isTicketSelectedCheck,
      refetchData,
      flightTypeOptions,
      saleReportTicketTypeOptions,
      trimUpperCaseInput,
      clearFilter,

      getSuggestionValue,
      agencySuggestions,
      inputSuggestionHandle,
      replaceText,
      dateTime,
      isRoleF1,
      isRoleF2,
      isRoleF3,
      onQuickChooseDateHandle,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style lang="scss" scoped>

.radio-custom, ::v-deep {
  .custom-radio .custom-control-label {
    margin-top: 0;
  }
}
.checkbox-custom ::v-deep {
  .custom-control-label {
    margin-top: 2px;
  }
}
</style>
